import React, { useState, useEffect, useContext, useRef } from 'react'
import './HistoryControl.scss'

const HistoryControl = ({ historyControlChange, historyUndoRedoAvailable, hide }) => {
    const controller = new AbortController();
    const { signal } = controller;
    const [debounce, setDebounce] = useState(-1)

    const bounce = () => {
        if (debounce > -1) {
            return true
        }
        let t = setTimeout(() => {
            setDebounce(-1)
        }, [100])
        setDebounce(t)
    }

    const onUndo = () => {
        if (!bounce()) {
            historyControlChange('undo')
        }
    }

    const onRedo = () => {
        if (!bounce()) {
            historyControlChange('redo')
        }
    }

    return (
        <div className={ hide ? 'display-none' : 'history-control' }>
                <div className={historyUndoRedoAvailable.undo && (debounce === -1) ? '' : 'off'} onClick={historyUndoRedoAvailable.undo && (debounce === -1) ? onUndo : null}>undo</div>
                <div className={historyUndoRedoAvailable.redo && (debounce === -1) ? '' : 'off'} onClick={historyUndoRedoAvailable.redo && (debounce === -1) ? onRedo : null}>redo</div>
        </div>
    );
}
export default HistoryControl

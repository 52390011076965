import React, { useState, useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility";
import './InputFont.scss'
const InputFont = ({ layerKey, input, setValue, useValue }) => {
    const { state } = useContext(StoreContext)
    const [chosenFont, setChosenFont] = useState(useValue);
    const [initialEffect, setInitialEffect] = useState(true)

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (chosenFont !== useValue) {
                setChosenFont(useValue)
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (chosenFont === null || chosenFont === undefined) {
            return
        }
        if (initialEffect) {
            setInitialEffect(false)
            return
        }
        if (chosenFont !== null && chosenFont !== undefined) {
            let existingFont = Utility.currentLayerInputValue(layerKey, input.inputKey, state.activeLayerValues)
            // if this input is already the same value as whats in state.activeLayerValues, do not re-send the same value back.
            if (existingFont !== chosenFont) {
                setValue(input.inputKey, chosenFont)
            }
        }
    }, [chosenFont])  // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = (evt) => {
        let value = evt.target.value
        if (value !== null && value !== undefined) {
            setChosenFont(value)
        }
    }

    // firefox is not sending the selected value to the onChange handler. onClick seems to do it ok, but only in FF.
    const onClick = (fontFamily) => {
        if (fontFamily !== null && fontFamily !== undefined) {
            setChosenFont(fontFamily)
        }
    }

    return (
        <div id={`input_font_${input.inputKey}`} className="default-input-container input-font">
            <span className="named">
                {input.comment && input.comment.includes('combat') ? 'combat ' : ''}
                {input.comment && input.comment.includes('movement') ? 'movement ' : ''}
                {input.named}:</span>
            <select className="" name={`fontSelect${layerKey}_${input.inputKey}`} id={`fontSelect${layerKey}_${input.inputKey}`} value={chosenFont} onChange={onChange}>
                {[...new Set(Array.from(state.fonts, f => {
                 if( f.fontWeight === 400 ) {
                     return f.fontFamily
                 }
                 else {
                    return f.fontFamily + '@' + f.fontWeight
                 }
                }
            ))].sort().map((fontFamily, index) => {
                    return <option onClick={() => onClick(fontFamily)} key={index} value={fontFamily}>{fontFamily}</option>
                })}
            </select>
        </div>
    )
}

export default InputFont
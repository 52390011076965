import React, { useState, useEffect, useContext, useRef } from 'react'
import { useSignal } from "@preact/signals-react"
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './InstallFont.scss'

const InstallFont = ({ close }) => {
    const { state, actions } = useContext(StoreContext)
    const [errorMessage, setErrorMessage] = useState('')
    const [loadMessage, setLoadMessage] = useState('')
    const [fontName, setFontName] = useState('')
    const [fontLoaded, setFontLoaded] = useState(null)
    const [fontInstalled, setFontInstalled] = useState(null)
    const signalFetchUrl = useSignal('')
    const exampleRef = useRef(null)
    const [fontSrc, setFontSrc] = useState('')
    const [chosenFontWeight, setChosenFontWeight] = useState('default')
    const [addedFontLink, setAddedFontLink] = useState(null)
    useEffect(() => {

    }, [])

    const changeFontName = evt => {
        setFontName(evt.target.value)
        setLoadMessage('')
    }

    const onLookup = evt => {
        let modifiedName = fontName
        // see if font is already installed
        let alreadyInstalledFont = state.fonts.find(sf => sf.fontFamily === fontName)
        if (alreadyInstalledFont) {
            setErrorMessage('This font is already installed!')
        }
        else {
            setErrorMessage('')
        }


        modifiedName = modifiedName.replace(/\s+/g, '+')
        let tryUrl = "https://fonts.googleapis.com/css2?family=" + modifiedName + "&text="//ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`~@#$%^&*()_-+={}[]|\"'<>,.?/"
        if (chosenFontWeight !== 'default') {
            tryUrl = "https://fonts.googleapis.com/css2?family=" + modifiedName + ":wght@" + chosenFontWeight + "&text="//ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`~@#$%^&*()_-+={}[]|\"'<>,.?/"
        }
        console.log('tryUrl:', tryUrl)
        signalFetchUrl.value = tryUrl
        goGetIt(tryUrl)
    }

    const goGetIt = (url) => {
        fetchCSS(url)
            .then(embedFonts)
            .then(outputResult)
            .catch(errorHandler)
    }

    /*
fetch(url).then((response) => {
  if (response.ok) {
    return response.json();
  }
  throw new Error('Something went wrong');
})
.then((responseJson) => {
  // Do something with the response
})
.catch((error) => {
  console.log(error)
});

    */

    const fetchCSS = url => {
        setLoadMessage('')
        return fetch(url).then((response) => {
            if (response.ok) {
                return response.text()
            }
        })
            .then((responseText) => {
                return responseText
            })
            .catch((error) => {
                if( chosenFontWeight !== 'default' ) {
                    let tryUrl = signalFetchUrl.value
                    tryUrl = tryUrl.replace(':wght@' + chosenFontWeight, '')
                    setChosenFontWeight('default')
                    signalFetchUrl.value = tryUrl
                    goGetIt(tryUrl)
                }
                else {
                    setLoadMessage('That font name seems to be incorrect. ' + error)
                }
            })

    }

    function embedFonts(cssText) {
        var fontLocations = cssText.match(/https:\/\/[^)]+/g)
        var fontLoadedPromises = fontLocations.map(function (location) {
            return new Promise(function (resolve, reject) {
                fetch(location).then(function (res) {
                    return res.blob()
                }).then(function (blob) {
                    var reader = new FileReader()
                    reader.addEventListener('load', function () {
                        // Side Effect
                        cssText = cssText.replace(location, this.result)
                        resolve([location, this.result])
                    })
                    reader.readAsDataURL(blob)
                }).catch(reject)
            })
        })
        return Promise.all(fontLoadedPromises).then(function () {
            return cssText
        })
    }

    const outputResult = (cssText) => {
        let fontObj = interpretCssText(cssText)
        console.log('fontObj:', fontObj)
        if( fontObj.fontWeight !== 400 ) {
          //fontObj.fontFamily += ':' + fontObj.fontWeight
        }
        if (fontObj) {
            addFontToDocument(fontObj)
            setFontLoaded(fontObj)
            setErrorMessage('')
        }
        else {
            console.error('error loading font')
        }
    }

    const interpretCssText = cssText => {
        let arr = cssText.split("\n")
        let _fontObj = {
            fontFamily: '',
            fontStyle: '',
            fontWeight: 0,
            fontSrc: ''
        }
        arr.forEach((e, i) => {
            if (e.includes('src:')) {
                let srcString = e;
                srcString = srcString.replace('src:', '')
                srcString = srcString.trim()
                _fontObj.fontSrc = srcString
                setFontSrc(srcString)
            }
            else {
                let pair = e.split(':')
                if (pair[0].includes('font-family')) {
                    let val = pair[1]
                    if (val) {
                        _fontObj.fontFamily = cleanResult(val, true)
                    }
                }
                if (pair[0].includes('font-style')) {
                    let val = pair[1]
                    if (val) {
                        _fontObj.fontStyle = cleanResult(val)
                    }
                }
                if (pair[0].includes('font-weight')) {
                    let val = pair[1]
                    if (val) {
                        _fontObj.fontWeight = cleanResult(val)
                    }
                }
            }
        })

        let splitUrl = signalFetchUrl.value.split('&text=')
        if (splitUrl.length === 2) {
            let urlEncoded = splitUrl[0] + '&text=' + encodeURIComponent(splitUrl[1])
            _fontObj.fontUrl = urlEncoded
            _fontObj.fontUnicodeRange = generateUnicodeRanges('-+_@#$%^&*()[]ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789')
            _fontObj.fontText = splitUrl[1]
            if (Utility.isNumeric(_fontObj.fontWeight)) {
                _fontObj.fontWeight = Number(_fontObj.fontWeight)
            }
            else {
                _fontObj.fontWeight = 0
            }
        }
        else {
            return null
        }

        return _fontObj
    }

    const cleanResult = (str, trimOnly = false) => {
        let strResult = str
        if (!trimOnly) {
            strResult = strResult.replace(/\s+/g, '')
        }
        else {
            strResult = strResult.trim()
        }
        strResult = strResult.replaceAll("'", '')
        strResult = strResult.replaceAll(';', '')
        return strResult
    }

    const errorHandler = (e) => {
        if (e && e.message) {
            console.error(e.message)
        }
        else {
            console.error(e)
        }
        setErrorMessage('could not load:', e)
    }

    const convertToUnicode = int => {
        let unicode = ''
        if (int) {
            unicode = int.toString(16)
            unicode = 'U+' + unicode
        }
        return unicode
    }

    const generateUnicodeRanges = str => {
        if (!str || typeof str !== 'string') {
            return ''
        }
        let integerCodes = []
        for (let i = 0; i < str.length; i++) {
            let char = str.charCodeAt(i)
            integerCodes.push(char)
        }
        integerCodes = integerCodes.sort(function (a, b) { return a - b })
        let range = []
        let ranges = []
        for (let index = 0; index < integerCodes.length; index++) {
            let int = integerCodes[index]
            if (index === 0) {
                range.push(int)
                // if there is only one character in the array, just end it now.
                if (index === integerCodes.length - 1) {
                    range.push(int)
                    ranges.push(range)
                }
                continue
            }
            if ((int === integerCodes[index - 1] + 1)) { // if this int is one more than what we looked at previously, we are ranging.

                // if we are at the end of the array, just push the last value in.
                if (index === integerCodes.length - 1) {
                    range.push(int)
                    ranges.push(range)
                }
                continue
            }
            else {
                // we have a break in the range
                range.push(integerCodes[index - 1]) // put the previous int (that was ranging) into the range holding array.
                ranges.push(range)
                range = [] // and reset range to empty
                range.push(int) // and push the new starting for the next range in.
                // if we are at the end of the array, just push the last value in.
                if (index === integerCodes.length - 1) {
                    ranges.push(range)
                }
            }
        }
        let unicodeRanges = []
        ranges.forEach(range => {
            if (range.length === 1) {
                let rangeStart = range[0]
                let unicodeStart = convertToUnicode(rangeStart)
                unicodeRanges.push(unicodeStart)
            }
            if (range.length === 2) {
                let rangeStart = range[0]
                let unicodeStart = convertToUnicode(rangeStart)
                let rangeEnd = range[1]
                let unicodeEnd = convertToUnicode(rangeEnd)
                if (unicodeStart === unicodeEnd) {
                    unicodeRanges.push(unicodeStart)
                }
                else {
                    unicodeRanges.push(unicodeStart + '-' + unicodeEnd.replace('U+', ''))
                }
            }
        })
        let unicodeString = unicodeRanges.join(',') + ';'
        return unicodeString
    }

    const addFontToDocument = async (_fontObj) => {
        if (_fontObj) {
            let link = document.createElement('link')
            link.href = signalFetchUrl.value;
            link.rel = "stylesheet";
            link.type = "text/css";
            link.id = _fontObj.fontFamily.replaceAll(' ', '_') + '@' + _fontObj.fontStyle + '@' + _fontObj.fontWeight
            exampleRef.current.appendChild(link)
            setAddedFontLink(link)
        }
    }

    const removeFontFromDocument = () => {
        if (addedFontLink) {
            exampleRef.current.removeChild(addedFontLink)
            setAddedFontLink(null)
        }
    }

    const onInstall = () => {
        addFontToState()
    }



    const addFontToState = () => {
        // install to state. Dont put fontSrc into state, its too big.
        if (fontLoaded) {
            let fonts = [...state.fonts]
            let existingIndex = fonts.findIndex(ft => ft.fontFamily === fontLoaded.fontFamily &&
                ft.fontWeight === fontLoaded.fontWeight &&
                ft.fontStyle === fontLoaded.fontStyle)
            if (existingIndex >= 0) {
                fonts[existingIndex] = fontLoaded
            }
            else {
                fonts.push(fontLoaded)
            }

            if (errorMessage.includes('already installed')) {
                const linkId = fontLoaded.fontFamily.replaceAll(' ', '_') + '@' + fontLoaded.fontStyle + '@' + fontLoaded.fontWeight
                Utility.deleteFontFromDocument(linkId)
            }

            actions.fonts(fonts)
        }
    }

    useEffect(() => {
        if (fontLoaded && state.fonts) {
            let installedFound = state.fonts.find(sf => sf.fontFamily === fontLoaded.fontFamily &&
                sf.fontWeight === fontLoaded.fontWeight &&
                sf.fontStyle === fontLoaded.fontStyle)
            if (installedFound) {
                setFontInstalled(installedFound)
            }
        }
    }, [state.fonts]) // eslint-disable-line react-hooks/exhaustive-deps

    const onInstallAnother = () => {
        clearLocalState()
    }

    const onCancelInstall = () => {
        clearLocalState()
    }

    const onExit = () => {
        clearLocalState()
        close()
    }

    const clearLocalState = () => {
        setFontName('')
        setFontLoaded(null)
        setFontInstalled(null)
        removeFontFromDocument()
        setErrorMessage('')
        setChosenFontWeight('default')
        setLoadMessage('')
        signalFetchUrl.value = ''
    }

    const onChangeFontWeight = evt => {
        setChosenFontWeight(evt.target.value)
    }

    return (
        <div ref={exampleRef} className="install-font">
            <div className="font-install-explanation">
                <p>
                    You can install Google webfonts into this app.
                </p>
                <p>
                    To add a font, type in the font name (from Google) into the input box below.<br />
                    The default weight will usually be 400. If you want a lighter or heavier font weight, ensure it's available before setting the weight.
                </p>
                <p>
                    After you add a font, the font information and data will live on your computer, managed by your
                    browser. If your browser decides it needs to make room in its allocated memory space, it may delete your font. And it will do so without any warning or notice.
                </p>
            </div>
            <div className="google-url">Go to <a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer">https://fonts.google.com/</a> to browse fonts
                <div className={errorMessage ? 'font-name-issue' : 'display-none'}>
                    If the font doesn't load, the font name you use may need to be an abbreviated form - look at the top of the Google page and look for a url that looks like: <span>https://fonts.google.com/specimen/Playwrite+BE+WAL?query=playwrite</span> - In this case, you'll want to use <span>Playwrite BE WAL</span> as the font name.
                </div>
            </div>
            <div className={fontLoaded || fontInstalled ? 'display-none' : 'font-lookup'}>
                <span>
                    Google font name:
                </span>
                {fontLoaded && !fontInstalled ?
                    <span className="loaded-font-name">{fontName}</span>
                    :
                    <input type="text" className={errorMessage ? 'warning' : ''} placeholder="Sample Serif" value={fontName} onChange={changeFontName} />

                }
                <span>font weight:</span>
                <select id="fontWeight" value={chosenFontWeight} onChange={onChangeFontWeight}>
                    <option value="default">default</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="300">300</option>
                    <option value="400">400</option>
                    <option value="500">500</option>
                    <option value="600">600</option>
                    <option value="700">700</option>
                    <option value="800">800</option>
                    <option value="900">900</option>
                </select>
                <button className={fontLoaded || !fontName ? 'standard-button disabled' : 'standard-button blue'}
                    onClick={fontLoaded || !fontName ? null : onLookup}>Fetch font data</button>
            </div>

            <div className="error-message">{loadMessage}</div>

            {fontInstalled ?
                <div className="font-loaded-installed">
                    <div className="message">The font <span>{fontInstalled.fontFamily}</span> has been successfully installed.</div>
                    <div className="install-options">
                        <button className="standard-button green"
                            onClick={onInstallAnother}>Add another font</button>

                        <button className="standard-button red"
                            onClick={onExit}>exit</button>
                    </div>
                </div> : ''
            }
            {fontLoaded && !fontInstalled ?
                <div className="font-loaded-installed">
                    <div className="message">This is an example of the font you selected. If you approve, click the <b>install font</b> button.
                        Otherwise, click on the <b>try another font</b> button, and try some other font.
                    </div>
                    <div className="font-example" style={{ fontFamily: "'" + fontLoaded.fontFamily + "'", fontWeight: fontLoaded.fontWeight }}>
                        Font {fontName} has been loaded.
                        -+_@#$%^&*()[]ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789
                    </div>
                    {errorMessage ?
                        <div className="error-message">{errorMessage}</div>
                        : ''
                    }
                    <div className="install-options">
                        <button className="standard-button blue"
                            onClick={onInstall}>{errorMessage.includes('already installed') ? 'Re-install font' : 'Install font'}</button>

                        <button className="standard-button green"
                            onClick={onCancelInstall}>try another font</button>

                        <button className="standard-button red"
                            onClick={onExit}>exit</button>
                    </div>
                    {/* <textarea>{fontSrc}</textarea> */}
                </div>
                :
                ''}

        </div>
    );
}
export default InstallFont